import React from 'react';
import { stateHasData } from '@openstax/ts-utils/fetch';
import * as UI from '@openstax/ui-components';
import { useFrontendConfigValue } from '../configProvider/use';
import { useLtiLinkedUserData } from '../ltiLinkedAccount/context';

// If we have already recorded the user's consent_preferences, renders the children components
// Otherwise, renders a blank screen if the user has not interacted with the CookieYes banner,
// or a loader if they have interacted and are waiting for the page to reload
export const ConsentWrapper = ({ children }: React.PropsWithChildren<{}>) => {
  const userData = useLtiLinkedUserData();
  const skipConsentCheck = useFrontendConfigValue('skipConsentCheck');
  const [interactedWith, setInteractedWith] = React.useState<boolean>(false);

  const consentUpdatedCallback = React.useCallback(() => {
    setInteractedWith(true);
  }, []);
  React.useEffect(() => {
    document.addEventListener('cookieyes_consent_update', consentUpdatedCallback);
    return () => document.removeEventListener('cookieyes_consent_update', consentUpdatedCallback);
  }, [consentUpdatedCallback]);

  return (stateHasData(skipConsentCheck) && skipConsentCheck.data === 'true')
    || userData.user?.consent_preferences ? <>{children}</> :
      interactedWith ? <UI.Loader /> : null;
};
