"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeSyncScoresCompleteMessage = exports.isSyncScoresCompleteMessage = exports.makeSyncScoresMessage = exports.isSyncScoresMessage = exports.makeAccountLinkedMessage = exports.isAccountLinkedMessage = exports.makeActivityProgressMessage = exports.isActivityProgressMessage = exports.makeDeepLinkContentSelectedMessage = exports.isDeepLinkContentSelectedMessage = void 0;
const guards_1 = require("@openstax/ts-utils/guards");
const isDeepLinkContentSelectedMessage = (message) => (0, guards_1.isPlainObject)(message) && 'type' in message && message.type === 'DeepLinkContentSelectedMessage';
exports.isDeepLinkContentSelectedMessage = isDeepLinkContentSelectedMessage;
const makeDeepLinkContentSelectedMessage = (items) => ({
    type: 'DeepLinkContentSelectedMessage',
    payload: { items }
});
exports.makeDeepLinkContentSelectedMessage = makeDeepLinkContentSelectedMessage;
const isActivityProgressMessage = (message) => (0, guards_1.isPlainObject)(message) && 'type' in message && message.type === 'ActivityProgressMessage' &&
    (0, guards_1.isPlainObject)(message.payload) &&
    typeof message.payload.id === 'string' && typeof message.payload.isComplete === 'boolean';
exports.isActivityProgressMessage = isActivityProgressMessage;
const makeActivityProgressMessage = ({ id, isComplete, syncPartialScores }) => ({
    type: 'ActivityProgressMessage',
    payload: { id, isComplete, syncPartialScores },
});
exports.makeActivityProgressMessage = makeActivityProgressMessage;
const isAccountLinkedMessage = (message) => (0, guards_1.isPlainObject)(message) && 'type' in message && message.type === 'AccountLinkedMessage';
exports.isAccountLinkedMessage = isAccountLinkedMessage;
const makeAccountLinkedMessage = () => ({
    type: 'AccountLinkedMessage'
});
exports.makeAccountLinkedMessage = makeAccountLinkedMessage;
const isSyncScoresMessage = (message) => (0, guards_1.isPlainObject)(message) && 'type' in message && message.type === 'SyncScoresMessage' &&
    (0, guards_1.isPlainObject)(message.payload) && typeof message.payload.id === 'string' &&
    typeof message.payload.syncIncompleteScores === 'boolean';
exports.isSyncScoresMessage = isSyncScoresMessage;
const makeSyncScoresMessage = (id, syncIncompleteScores) => ({
    type: 'SyncScoresMessage',
    payload: { id, syncIncompleteScores },
});
exports.makeSyncScoresMessage = makeSyncScoresMessage;
const isSyncScoresCompleteMessage = (message) => (0, guards_1.isPlainObject)(message) && 'type' in message && message.type === 'SyncScoresCompleteMessage' &&
    Array.isArray(message.failures) &&
    Array.isArray(message.skippedIds) &&
    Array.isArray(message.successIds);
exports.isSyncScoresCompleteMessage = isSyncScoresCompleteMessage;
const makeSyncScoresCompleteMessage = ({ failures, skippedIds, successIds }) => ({
    type: 'SyncScoresCompleteMessage',
    failures,
    skippedIds,
    successIds,
});
exports.makeSyncScoresCompleteMessage = makeSyncScoresCompleteMessage;
